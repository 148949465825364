document.addEventListener('alpine:init', () => {

  newsletterForm();

  function newsletterForm() {

    Alpine.data('newsletterForm', () => ({
      formLoaded() {
        const form = this.$refs.form;

        this.form = this.$refs.form;
        this.formData._token = this.form.querySelector('input[name="_token"]').value;
      },
      async submitForm(event) {

        this.isSubmitting = true;
        this.errors = [];
        this.success = false;

        this.formData.gdpr = {
          email: true
        };

        try {
          const response = await fetch(event.target.action, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(this.formData),
          });

          if (response.ok) {
            const result = await response.json();
            if (result.success) {
              this.success = true;
              this.$refs.form.scrollIntoView({
                behavior: 'smooth'
              });

              this.resetForm();
            } else if (result.errors) {
              this.errors = result.errors;
            }
          } else {
            const errorData = await response.json();
            this.errors = errorData.errors || ["Something went wrong. Please try again later."];
          }
        } catch (error) {
          console.error(error);
          this.$refs.form.scrollIntoView({
            behavior: 'smooth'
          });
          this.errors = ["An unexpected error occurred. Please try again later."];
        } finally {
          this.isSubmitting = false;
        }
      },
      resetForm() {
        this.form.reset();
        this.formData = {
          firstName: '',
          lastName: '',
          email: '',
          gdpr_email: false,
          _token: this.formData._token,
        };
      },
      errors: [],
      success: false,
      isSubmitting: false,
      form: null,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        gdpr_email: false,
        _token: '', // Set this in init
      }
    }));

  }

});